//Copyright by Chaafo pvt ltd
//All the footer design is made here in this file


import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form, InputGroup, FormControl, Button, Image } from 'react-bootstrap';
import FontAwesome from './FontAwesome';
import { toast } from 'react-toastify';
import Icofont from 'react-icofont';
import 'react-toastify/dist/ReactToastify.css';
import FooterCustom from '../FooterPagesContent';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';
import { SiteColors } from '../../constants/colorTheme';

function Footer(props) {

    const { MK, pages } = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");

    const [playlink, setplaylink] = useState('');
    const [applink, setapplink] = useState('');
    const [fblink, setFBlink] = useState('');
    const [instalink, setInstalink] = useState('');
    const [googlelink, setgooglelink] = useState('');
    const [pageID, setPageID] = useState('');
    const initialValues = { email: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    // Function to set the email(user enters for subscribing newsletter) to formvalues
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    // Function to send formvalues in validate function
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {

        if (Object.keys(formErrors).length === 0 && isSubmit) {
            newsletter();
        }

    }, [formErrors]);

    // Function to check whether the email entered by the user is in correct format or not
    const validate = (values) => {
        const errors = {};

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{3,}$/i;

        if (!values.email) {
            errors.email = "*Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        return errors;
    };



    const history = useHistory();
    toast.configure();

    //function to subscribe newsletter 
    const newsletter = () => {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("email_address", formValues.email);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.NewsLetter + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    toast.success(result.msg, {
                        position: 'top-center',
                        autoClose: 400
                    });
                }
                else {
                    toast.error("Not logged in", {
                        position: 'top-center',
                        autoClose: 400
                    });
                }

            })
    }

    //function to scroll the tab to top
    const scrollTotop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const appurl = () => {
        fetch(urls.AppUrl + "?merchant_keys=" + MK)
            .then(response => response.json())
            .then(result => {
                if (result.details) {
                    setplaylink(result.details.playstore?.option_value || '');
                    setFBlink(result.details.facebook || '');
                    setInstalink(result.details.instagram || '');
                    setgooglelink(result.details.playstore || '');
                } else {
                    console.error("Invalid data structure:", result);
                }
            })
            .catch(error => {
                console.error("Error fetching app URLs:", error);
            });
    };

    useEffect(() => {

        appurl();
    }, [MK]);
    // function to fetch app url so that we can redirect user to another tab from where he can download playstore app

    // functions to open new window of facebook page, playstore page, instagram page and googlepage    
    const playpage = () => {
        window.open(playlink)
    }
    const fbpage = () => {
        window.open(fblink.option_value)
    }
    const instapage = () => {
        window.open(instalink.option_value)
    }
    const googlepage = () => {
        window.open(googlelink.option_value)
    }

    useEffect(() => {
        const currentPageTitle = window.location.pathname.replace('/', '').replace(/%20/g, ' '); // Extracts page title from URL
        const currentPage = pages?.find(page => page.title === currentPageTitle); // Finds the page matching the URL
        
        if (currentPage) {
            sessionStorage.setItem("page_id", currentPage.page_id); // Sets page_id based on URL
        }
    }, [pages]);

    return (
        <>
            <section className="footer pt-5 pb-5" style={{background: SiteColors.secondary }}>
                <Container>
                    <Row>
                        <Col md={4} sm={12}>
                            <h6 className="mb-3">Subscribe to our Newsletter</h6>
                            <Form className="newsletter-form mb-1">
                                <InputGroup className="mb-3">
                                    <FormControl
                                        type="email"
                                        name="email"
                                        value={formValues.email}
                                        onChange={handleChange}
                                        placeholder="Please enter your email"
                                    />
                                    <InputGroup.Append>
                                        <Button type="button" variant="primary" onClick={handleSubmit}>Subscribe</Button>
                                    </InputGroup.Append>

                                </InputGroup>
                            </Form>
                            <p className='Val'>{formErrors.email}</p>
                            <p><Link className="text-info" to="register">Register now</Link> to get updates on <Link to="offers" onClick={scrollTotop}>Offers and Coupons</Link></p>
                            {/*<div className="app">
                                <p className="mb-2">DOWNLOAD APP</p>
                                <Link>
                                    <Image onClick={appurl} onClickCapture={playpage} className="google" src="img/google.png" alt='google logo' fluid />
                                </Link>
                            </div>*/}
                        </Col>
                        <Col md={1} sm={6} className="mobile-none">
                        </Col>
                        <Col md={2} sm={4} xs={6}>
                            {/* <h6 className="mb-3">About Us</h6> */}
                            <ul>
                                {pages?.map(page =>
                                    <li className="footer-title">
                                        <Link to={`/${page.title.replace(/\s+/g, '')}`} onClick={() => {
                                            sessionStorage.setItem("page_id", page.page_id)
                                            scrollTotop();
                                        }
                                        }>{page.title}</Link>
                                        {/* <p>{page.page_id}</p> */}
                                    </li>
                                )}
                                {/* <li><Link to="#">Culture</Link></li>
                                <li><Link to="#">Blog</Link></li>
                                <li><Link to="#">Careers</Link></li>
                                <li><Link to="#">Contact</Link></li> */}
                            </ul>
                        </Col>
                        {/* <Col md={2} sm={4} xs={6}>
                            <h6 className="mb-3">For Foodies</h6>
                            <ul>
                                <li><Link to="#">Community</Link></li>
                                <li><Link to="#">Developers</Link></li>
                                <li><Link to="#">Blogger Help</Link></li>
                                <li><Link to="#">Verified Users</Link></li>
                                <li><Link to="#">Code of Conduct</Link></li>
                            </ul>
                        </Col>
                        <Col md={2} sm={4} xs={4} className="m-none">
                            <h6 className="mb-3">For Restaurants</h6>
                            <ul>
                                <li><Link to="#">Advertise</Link></li>
                                <li><Link to="#">Add a Restaurant</Link></li>
                                <li><Link to="#">Claim your uling</Link></li>
                                <li><Link to="#">For Businesses</Link></li>
                                <li><Link to="#">Owner Guidelines</Link></li>
                            </ul>
                        </Col> */}
                    </Row>
                </Container>
            </section>
            <section className={props.sectionclassName}>
                <div className="container">
                    <Row>
                        <Col xs={12}>
                            <Container>
                                <p className="mt-0 mb-0 text-center">{props.copyrightText}</p>
                            </Container>
                            
                            {/* social-media-links section */}

                            <div className='mt-0 mb-0 text-center'>
                                {fblink != null ?
                                    <Link onClick={fbpage} >
                                        <Icofont className='pl-2' icon="icofont-facebook" /></Link> : " "
                                }
                                {/* {instalink != null ?
                                    <Link onClick={instapage} >
                                        <Icofont className='pl-2' icon="icofont-instagram" /></Link> : ""}
                                {googlelink != null ?
                                    <Link onClick={googlepage} >
                                        <Icofont className='pl-2' icon="icofont-google-plus" /></Link> : " "} */}
                            </div>

                            {/* <p className={props.popularCHclassName}>POPULAR COUNTRIES</p>
                          <div className="search-links">
                             <Link to="#">Australia</Link> |  
                             <Link to="#">Brasil</Link> | 
                             <Link to="#">Canada</Link> |  
                             <Link to="#">Chile</Link>  |  
                             <Link to="#">Czech Republic</Link> |  
                             <Link to="#">India</Link>  |  
                             <Link to="#">Indonesia</Link> |  
                             <Link to="#">Ireland</Link> |  
                             <Link to="#">New Zealand</Link> | 
                             <Link to="#">United Kingdom</Link> |  
                             <Link to="#">Turkey</Link>  |  
                             <Link to="#">Philippines</Link> |  
                             <Link to="#">Sri Lanka</Link>  |  
                             <Link to="#">Australia</Link> |  
                             <Link to="#">Brasil</Link> | 
                             <Link to="#">Canada</Link> |  
                             <Link to="#">Chile</Link>  |  
                             <Link to="#">Czech Republic</Link> |  
                             <Link to="#">India</Link>  |  
                             <Link to="#">Indonesia</Link> |  
                             <Link to="#">Ireland</Link> |  
                             <Link to="#">New Zealand</Link> | 
                             <Link to="#">United Kingdom</Link> |  
                             <Link to="#">Turkey</Link>  |  
                             <Link to="#">Philippines</Link> |  
                             <Link to="#">Sri Lanka</Link> |  
                             <Link to="#">Australia</Link> |  
                             <Link to="#">Brasil</Link> | 
                             <Link to="#">Canada</Link> |  
                             <Link to="#">Chile</Link>  |  
                             <Link to="#">Czech Republic</Link> |  
                             <Link to="#">India</Link>  |  
                             <Link to="#">Indonesia</Link> |  
                             <Link to="#">Ireland</Link> |  
                             <Link to="#">New Zealand</Link> | 
                             <Link to="#">United Kingdom</Link> |  
                             <Link to="#">Turkey</Link>  |  
                             <Link to="#">Philippines</Link> |  
                             <Link to="#">Sri Lanka</Link>  |  
                             <Link to="#">Australia</Link> |  
                             <Link to="#">Brasil</Link> | 
                             <Link to="#">Canada</Link> |  
                             <Link to="#">Chile</Link>  |  
                             <Link to="#">Czech Republic</Link> |  
                             <Link to="#">India</Link>  |  
                             <Link to="#">Indonesia</Link> |  
                             <Link to="#">Ireland</Link> |  
                             <Link to="#">New Zealand</Link> | 
                             <Link to="#">United Kingdom</Link> |  
                             <Link to="#">Turkey</Link>  |  
                             <Link to="#">Philippines</Link> |  
                             <Link to="#">Sri Lanka</Link>
                          </div> */}
                            {/* <p className={props.popularFHclassName}>POPULAR FOOD</p>
                            <div className="search-links">
                                <Link to="#">Fast Food</Link> |
                                <Link to="#">Chinese</Link> |
                                <Link to="#">Street Food</Link> |
                                <Link to="#">Continental</Link>  |
                                <Link to="#">Mithai</Link> |
                                <Link to="#">Cafe</Link>  |
                                <Link to="#">South Indian</Link> |
                                <Link to="#">Punjabi Food</Link> |
                                <Link to="#">Fast Food</Link> |
                                <Link to="#">Chinese</Link> |
                                <Link to="#">Street Food</Link> |
                                <Link to="#">Continental</Link>  |
                                <Link to="#">Mithai</Link> |
                                <Link to="#">Cafe</Link>  |
                                <Link to="#">South Indian</Link> |
                                <Link to="#">Punjabi Food</Link> |
                                <Link to="#">Fast Food</Link> |
                                <Link to="#">Chinese</Link> |
                                <Link to="#">Street Food</Link> |
                                <Link to="#">Continental</Link>  |
                                <Link to="#">Mithai</Link> |
                                <Link to="#">Cafe</Link>  |
                                <Link to="#">South Indian</Link> |
                                <Link to="#">Punjabi Food</Link> |
                                <Link to="#">Fast Food</Link> |
                                <Link to="#">Chinese</Link> |
                                <Link to="#">Street Food</Link> |
                                <Link to="#">Continental</Link>  |
                                <Link to="#">Mithai</Link> |
                                <Link to="#">Cafe</Link>  |
                                <Link to="#">South Indian</Link> |
                                <Link to="#">Punjabi Food</Link>
                            </div> */}
                        </Col>
                    </Row>
                </div>
            </section>
            {/* <footer className="pt-4 pb-4 text-center">
                
            </footer> */}
        </>
    );
}

Footer.propTypes = {
    id: PropTypes.number,
    sectionclassName: PropTypes.string,
    popularCHclassName: PropTypes.string,
    popularCountries: PropTypes.array,
    popularFHclassName: PropTypes.string,
    popularFood: PropTypes.array,
    copyrightText: PropTypes.string,
    madewithIconclassName: PropTypes.string,
    firstLinkText: PropTypes.string,
    firstLink: PropTypes.string,
    secondLinkText: PropTypes.string,
    secondLink: PropTypes.string,
};

Footer.defaultProps = {
    sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
    popularCHclassName: 'text-black',
    popularCountries: [],
    popularFHclassName: 'mt-4 text-black',
    popularFood: [],
    copyrightText: '© Copyright 2022 chAafo. All Rights Reserved',
    madewithIconclassName: 'heart heart-icon text-danger',
    // firstLinkText: 'Sumit Verma',
    // firstLink: "//www.instagram.com/iamgurdeeposahan/",
    // secondLinkText: 'Askbootstrap',
    secondLink: '//askbootstrap.com/',
}

export default Footer;
